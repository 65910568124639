<template>
  <MainLayout class="list_radius-all">
    <DeletePopupAdmin
      title="Аккаунт"
      description="Вы действительно хотите удалить профиль? Оплаченный доступ по приложению и карте пропадёт."
      :dialog="dialog"
      @closeDeletePopup="closeDeletePopup"
      :delete-function="deleteFunction"
    />
    <template v-slot:navbar-title>{{ fullName ? fullName : "" }} </template>

    <div class="list__content-adding">
      <div class="title card">
        <div
          v-if="
            this.$store.getters.getCurrentRole == 70 ||
              this.$store.getters.getCurrentRole == 60 ||
              this.$store.getters.getCurrentRole == 50 ||
              this.$store.getters.getCurrentRole == 30 ||
              this.$store.getters.getCurrentRole == 31
          "
          class="title__avatar title__avatar_grey title__avatar-admin"
        ></div>

        <div
          v-else
          class="title__avatar title__avatar_grey title__avatar-user"
        ></div>
        <p class="title__text">{{ currentRole }}</p>

        <router-link to="/profile/edit" class="btn btn_color_grey btn-edit">
          Редактировать
        </router-link>
      </div>
    </div>

    <div class="keep keep-user">
      <BasicTableWithoutHeader
        v-if="sourceObject"
        :table-data="getDataRequisites"
      >
        <template #item.4.value="{item}">
          <p
            :style="
              item ? 'color: #95C23D !important' : 'color: #EB5C6D !important'
            "
          >
            {{ item.value }}
          </p>
        </template>
        <template
          v-if="
            !showRestore &&
              !showConfirmation &&
              currentUser &&
              !this.$store.state.auth.currentUser.marked_to_clean_at
          "
          #item.6.value="{}"
        >
          <v-btn
            type="submit"
            @click="dialogShow"
            color="#EB5C6D"
            class="btn btn-delete btn_color_red"
          >
            Удалить
          </v-btn>
        </template>
        <template
          v-else-if="
            showConfirmation &&
              !this.$store.state.auth.currentUser.marked_to_clean_at
          "
          #item.6.value="{}"
        >
          <div class="d-flex">
            <ValidationInputField
              v-if="!sendSMS"
              label="4 цифры входящего СМС"
              v-model="code"
              validate-name="входящего СМС"
              mask="####"
              rules="required"
              class="mr-4"
            />
            <ValidationInputField
              v-else
              label="Код подтверждения"
              v-model="code"
              validate-name="Код подтверждения"
              mask="####"
              rules="required"
            />
            <div class="flex-ver-center">
              <p
                class="authoriz__text-input  ml-2 mr-2"
                style="font-size: 14px"
                v-if="codeExp"
              >
                Повторить отправку<br />
                через
                <span class="timer">{{ codeExp }}</span>
              </p>
              <v-btn
                v-if="sendSMS && !codeExp"
                type="submit"
                @click="deleteProfileSms"
                class="btn btn_color_green authoriz__btn btn-change ml-4 mr-4"
              >
                Отправить СМС
              </v-btn>
            </div>
            <v-btn
              type="submit"
              @click="clickDelete"
              class="btn btn_color_white"
            >
              Подтвердить
            </v-btn>
          </div>
        </template>
        <template v-else #item.6.value="{}">
          <v-btn
            type="submit"
            @click="restoreFunction"
            class=" btn_color_white btn"
          >
            Восстановить
          </v-btn>
        </template>
      </BasicTableWithoutHeader>
    </div>
    <div
      v-if="
        this.$store.getters.getCurrentRole === 70 ||
          this.$store.getters.getCurrentRole === 60 ||
          this.$store.getters.getCurrentRole === 30 ||
          this.$store.getters.getCurrentRole === 31
      "
    >
      <div class="list__content-adding">
        <div class="title card">
          <div
            class="title__avatar title__avatar_grey title__avatar-docs"
          ></div>
          <p class="title__text">Реестры</p>
        </div>
      </div>

      <v-data-table
        v-if="dessert"
        :headers="headers"
        :items="desserts"
        hide-default-footer
        class="ym-hide-content"
      >
        <template v-slot:item.status="{ item }">
          <div style="max-width: 130px" class="d-flex justify-space-between">
            {{ item.status }}
            <v-icon dense color="gray" class="mr-2" @click="download(item)">
              mdi-tray-arrow-down
            </v-icon>
          </div>
        </template>
      </v-data-table>
    </div>
  </MainLayout>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import BasicTableWithoutHeader from "@/components/tables/BasicTableWithoutHeader";
import { dictionariesHelper } from "@/helpers/dictionariesHelper";
import moment from "moment";
import tableColsPassageLog from "@/helpers/tableCols/passageLog";
import phoneFilter from "@/helpers/filters/phoneFilter";
import ValidationInputField from "@/components/ValidationField/ValidationInputField";
import DeletePopupAdmin from "@/components/popup/DeletePopupCommon";

import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";

export default {
  name: "ProfilePage",
  components: {
    BasicTableWithoutHeader,
    MainLayout,
    ValidationInputField,
    DeletePopupAdmin
  },
  data: () => ({
    showConfirmation: false,
    code: null,
    showRestore: false,
    dialog: false,
    codeExp: false,
    timer: null,
    sendSMS: false,
    headers: [
      { text: "Название", value: "name", sortable: false },
      { text: "Статус", value: "status", sortable: false },
      { value: "actions", sortable: false },

      { text: "Тип объекта", value: "objectsType", sortable: false },
      { text: "Название объекта", value: "objectsName", sortable: false },
      { text: "Дата выгрузки", value: "start_date", sortable: false }
    ]
  }),
  computed: {
    header() {
      return tableColsPassageLog;
    },
    fullName() {
      return this.$store.getters.getFullName;
    },
    currentUser() {
      return this.$store.getters.getCurrentUser;
    },
    dessert() {
      return this.$store.getters.getDesserts;
    },
    sourceObject() {
      return this.$store.getters.getUserProfile;
    },
    desserts() {
      if (this.dessert.results) {
        return Array.from(this.dessert.results).map(el => ({
          id: el.id,
          // objectsType: el.object.type,
          objectsType:
            el.object != null
              ? dictionariesHelper.objectType[el.object.type]
              : "-",
          objectsName: el.object != null ? el.object.name : "-",
          name: el.type_translate,
          start_date: moment(el.created_at * 1000).format(
            "DD.MM.YYYY HH:mm:ss"
          ),
          end_date: moment(el.edited_at * 1000).format("DD.MM.YYYY HH:mm:ss"),
          status:
            el.status == "finished"
              ? "Готов"
              : el.status == "error"
              ? "Ошибка"
              : "Формируется..."
        }));
      } else return [];
    },
    getDataRequisites() {
      return [
        {
          text: "ФИО",
          value: this.fullName
        },
        {
          text: "Телефон",
          value: phoneFilter.execute(this?.sourceObject?.phone_number)
        },
        {
          text: "E-mail",
          value: this.sourceObject.email ? this.sourceObject.email : "-"
        },
        {
          text: "Статус",
          value:
            dictionariesHelper.accountsStatus.userStatus[
              this.sourceObject.is_active
            ]
        },
        {
          text: "Регистрация",
          value:
            dictionariesHelper.accountsStatus.userAuth[
              this.sourceObject.is_phone_confirmed
            ]
        },
        {
          text: "ID",
          value: this.sourceObject.id
        },
        {
          text: "Удалить профиль",
          value: this.sourceObject.id
        }
      ];
    },
    currentRole() {
      return this.$store.getters.getCurrentRoleName;
    },
    temp_token() {
      return this.$store.getters.getTempToken;
    }
  },
  created() {
    this.isDateLoaded = true;
    this.$store.dispatch("fetchUserProfile").finally(() => {
      this.isDateLoaded = false;
    });
  },
  methods: {
    startTimer() {
      const time = 3 * 60 * 1000;
      const startDate = Date.now();
      const endDate = startDate + time;
      const res = setInterval(() => {
        const remains = endDate - Date.now();

        if (remains <= 1000) {
          clearInterval(res);
        }
        this.codeExp = moment(remains).format("mm:ss");
        if (this.codeExp == "00:00") {
          this.sendSMS = true;
          this.codeExp = false;
        }
      }, 1000);
    },

    closeDeletePopup(data) {
      this.dialog = data;
    },
    download(item) {
      this.loadingBtn = true;
      this.$store.dispatch("downloadPassLogs", item).then(() => {
        this.loadingBtn = false;
      });
    },

    dialogShow() {
      this.dialog = true;
    },

    deleteFunction() {
      this.$store
        .dispatch("deleteProfile", { id: this.sourceObject.id })
        .then(() => {
          this.startTimer();
          this.isDateLoaded = true;
          this.showConfirmation = true;
          this.$store.dispatch("fetchUserProfile").finally(() => {
            this.isDateLoaded = false;
          });
        });
    },

    clickDelete() {
      this.$store
        .dispatch("deleteProfileConfirm", {
          code: this.code
        })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.remove(successMessageDictionary.profile)
          );
          this.$store.dispatch("fetchUserProfile").finally(() => {
            this.isDateLoaded = false;
          });
          this.isDateLoaded = true;
          this.showConfirmation = false;
          this.showRestore = true;
        });
    },
    restoreFunction() {
      this.isDateLoaded = true;
      this.$store
        .dispatch("restoreProfile", { id: this.sourceObject.id })
        .then(() => {
          this.showRestore = false;
          this.showConfirmation = false;

          this.$store.dispatch(
            "successNotification",
            actionMessage.restore(successMessageDictionary.profile)
          );
        })
        .then(() => {
          this.$store.dispatch("fetchUserProfile");
        });
    },
    deleteProfileSms() {
      this.$store
        .dispatch("deleteProfile", {
          id: this.sourceObject.id,
          data: {
            code_type: "sms"
          }
        })
        .then(() => {
          this.sendSMS = true;
          this.startTimer();
        })
        .catch(e => {
          if (e.response.status == 401) {
            this.$store.commit("SET_ERROR_NOTIFICATION", {
              errors: [
                {
                  field_verbose: "Ошибка",
                  message: "В системе данный номер не зарегистрирован!"
                }
              ]
            });
          }
        });
    }
  }
};
</script>

<style scoped></style>
